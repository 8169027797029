import { DokumentModel, DokumentUploadStatus } from '@dworkflow/shared/model/dokumente';
import { VorlagenDokumentModel } from '@dworkflow/shared/model/dokumente/vorlagen-dokument.model';
import { WorkflowDokumentModel } from '@dworkflow/shared/model/dokumente/workflow-dokument.model';
import { guid } from '@dworkflow/shared/utility/guid';
import * as fromState from '@dworkflow/state/dokumente.state';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';

export class DokumenteHelper {
  static containsOnlyGueltigeZeichen(ungueltigeZeichen: string[], dokumentName: string): boolean {
    return (
      !dokumentName.startsWith('.') &&
      this.getUngueltigeZeichenInDokumentnamen(ungueltigeZeichen, dokumentName).length === 0
    );
  }

  static getUngueltigeZeichenInDokumentnamen(
    ungueltigeZeichen: string[],
    dokumentName: string
  ): string[] {
    return ungueltigeZeichen.filter(ungueltigesZeichen =>
      dokumentName.includes(ungueltigesZeichen)
    );
  }

  static isUniqueDokumentName(dokumentenName: string, otherDokumenentenNamen: string[]): boolean {
    return !otherDokumenentenNamen
      .map(d => d?.toLowerCase())
      .includes(dokumentenName?.toLowerCase());
  }

  // Gibt ein Tuple (Dateiname, Extension) zurück
  static splitFileName(fileName: string): [string, string] {
    if (!fileName) {
      return [fileName, fileName];
    }
    const parts = fileName?.split('.');
    // Da der Punkt ein erlaubtes Zeichen ist, kann er auch im Dateinamen vorkommen
    // Deswegen nur alles entfernen, was nach dem letzten Punkt kommt
    return [parts.slice(0, -1).join('.'), parts[parts.length - 1]];
  }

  static getFileExtension(fileName: string): string {
    return this.splitFileName(fileName)[1];
  }

  static getNameWithoutFileExtension(fileName: string): string {
    return this.splitFileName(fileName)[0];
  }

  static isUploadOrRenameInProgress(dokument: DokumentModel): boolean {
    return (
      dokument.status === DokumentUploadStatus.UploadInProgress ||
      dokument.status === DokumentUploadStatus.Processing ||
      dokument.status === DokumentUploadStatus.Queued
    );
  }

  static isDokumentLoeschbar(dokument: DokumentModel): boolean {
    if ((dokument as VorlagenDokumentModel)?.vorlagenId) {
      return true;
    }

    const berechtigungen = (dokument as WorkflowDokumentModel)?.berechtigungen;
    return berechtigungen?.canEntfernen && !this.isUploadOrRenameInProgress(dokument);
  }

  static isDokumentFromVorlage(dokumentenId: guid, store: Store): Observable<boolean> {
    return store
      .select(fromState.selectDokumenteByDokumentenId(dokumentenId))
      .pipe(map((d: WorkflowDokumentModel) => !!d?.vorlagenDokumentId));
  }

  static getDokumentLink(dokument: DokumentModel): string {
    if (!dokument.relativeWebDavUrl) {
      return null;
    }

    const uriParts = dokument.relativeWebDavUrl.split('/');
    const last = encodeURIComponent(uriParts.pop());
    uriParts.push(last);
    return `${environment.dokumente.apiBase}/${uriParts.join('/')}`;
  }

  /**
   * Opens the item as protocol link if possible
   *
   * @returns boolean true if not possible (handle via html href)
   * @memberof FileLinkComponent
   */
  static open(dokument: DokumentModel): boolean {
    let type;
    const link: string = DokumenteHelper.getDokumentLink(dokument);
    if (dokument) {
      type = DokumenteHelper.getFileExtension(dokument.name);
    }

    const praefix = DokumenteHelper.getOfficePraefix(dokument.name);
    if (praefix == null) {
      if (type === 'pdf') {
        window.open(link);
        return false;
      }

      // do nothing if not in list --> will be openend as href in same tab by default event handler
      return true;
    }
    window.open(`${praefix}:ofe|u|${link}`);
    return false;
  }

  static getOfficePraefix(dokumentName: string): string {
    const type = DokumenteHelper.getFileExtension(dokumentName);

    switch (type) {
      case 'doc':
      case 'docx':
      case 'docm':
      case 'dot':
      case 'dotx':
        return 'ms-word';

      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'xltm':
      case 'xltx':
      case 'xlam':
      case 'csv':
        return 'ms-excel';

      case 'ppt':
      case 'pptx':
      case 'pptm':
      case 'pps':
      case 'ppsx':
        return 'ms-powerpoint';

      case 'pub':
      case 'puz':
        return 'ms-publisher';

      case 'vss':
      case 'vsdx':
      case 'vsd':
      case 'vst':
      case 'vdx':
      case 'vsx':
      case 'vtx':
        return 'ms-visio';

      case 'accdb':
      case 'accde':
      case 'accdt':
      case 'accdr':
        return 'ms-access';
      case 'mpp':
      case 'mpt':
      case 'mpx':
      case 'mpd':
        return 'ms-project';

      case 'spd':
        return 'ms-spd';

      case 'xsf':
      case 'xsn':
      case 'xsd':
      case 'xsl':
        return 'ms-infopath';
    }

    return null;
  }
}
